import React, {useEffect} from 'react'
import SEO from '../components/seo'
import Spacer from '../components/spacer'
import { Link } from 'gatsby'
import PostPreview from '../components/postPreview'


const Index = ({data}) => {
  const { edges: posts } = data.allMarkdownRemark;
  useEffect(() => {
    document.body.classList.add('home-page');
    return () => {
      document.body.classList.remove('home-page');
    }
  }, [])
  return (
    <>
    <div className="home-page">
      <SEO title="Home" />
      <div className="home-content">
        <div className="home-splash">
        <div className="hello-there">
          <h2>Hello there. <span role="img" aria-label="hand waving" className="emoji">👋</span></h2>
        </div>
        <div className="intro">
          <p>My name is Kevin and this is my website.</p>
          <p>This is pretty much a space for me to mess around with ideas. There is not really a focus on any one thing.</p>
            <p>I am a designer, developer, and aspiring maker. I wanted to create a space where I can explore and document some of those things.</p>
          <p>That is about all there is to it. <span role="img" aria-label="smile" className="emoji">🙂</span></p>
        </div>
        </div>
        <Spacer height="medium" />
        <div className="current-things-wrapper">
          <div className="row">
          <h3 className="current-things-title">Current Posts</h3>
          <Link to={'/blog/'}>View More</Link>
          </div>
        <div className="current-things-list blog-posts">
          
          {posts.slice(0,3)
            .filter(post => post.node.frontmatter.title.length > 0)
            .map(({ node: post }, i) => {
              return (
                <PostPreview key={i} post={post} />
              );
            })}
          </div>
          </div>
      </div>
    </div>
    </>
  )
}

export const currentQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            tags
          }
        }
      }
    }
  }
`;


export default Index
